import { booking } from '@/api/booking'
import { invoice } from '@/api/invoice'
import 'devextreme/dist/css/dx.light.css'
import { Workbook } from 'exceljs'
import { exportDataGrid } from 'devextreme/excel_exporter'
import ConfirmDialog from '@core/components/confirm-dialog/ConfirmDialog.vue'
import DeleteDialog from '@core/components/delete-dialog/DeleteDialog.vue'
import { saveAs } from 'file-saver-es'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import {
  required, min, max, alpha_num,
} from '@validations'
import { convertUTC } from '@/@core/utils/utils'
import {
  DxDataGrid,
  DxColumn,
  DxEditing,
  DxFilterRow,
  DxHeaderFilter,
  DxGroupPanel,
  DxGrouping,
  DxScrolling,
  DxPaging,
  DxPager,
  DxSummary,
  DxLookup,
  DxTotalItem,
  DxGroupItem,
  DxMasterDetail,
  DxStringLengthRule,
  DxRequiredRule,
  DxRangeRule,
  DxValueFormat,
  DxColumnChooser,
  DxColumnFixing,
  DxSelection,
  DxExport,
} from 'devextreme-vue/data-grid'

const dataGridRef = 'dataGrid'

export default {
  name: 'config-form-serial',
  components: {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxFilterRow,
    DxHeaderFilter,
    DxGroupPanel,
    DxGrouping,
    DxScrolling,
    DxPaging,
    DxPager,
    DxSummary,
    DxLookup,
    DxTotalItem,
    DxGroupItem,
    DxMasterDetail,
    DxStringLengthRule,
    DxRequiredRule,
    DxRangeRule,
    DxValueFormat,
    DxColumnChooser,
    DxColumnFixing,
    DxSelection,
    ValidationProvider,
    ValidationObserver,
    DxExport,
    'confirm-dialog': ConfirmDialog,
    'delete-dialog': DeleteDialog,
  },
  data() {
    return {
      isOpen: false,
      type: 'ADD',
      // listAction: [],
      // listBDC: [],
      // listKeyCodeAction: [],
      disableButton: false,
      IsDefaultInovice: false,
      CheckLishHasDefaultInovice: false,
      ListFormNoSerialNo: [],
      selectedRowsData: [],
      currentPage: 1,
      perPage: 50,
      dataGridRef,
      InvoiceFormNoSerialNo: {
        Id: null,
        FormNo: null,
        SerialNo: null,
        Active: true,
        DefaultInvoiceSymbol: false,
      },
      DetailInvoiceFormNoSerialNo: null,
      dataPropsModalConfigInvoiceFormNoSerialDetail: {
        type: null,
        data: null,
      },
      optionPerPage: [10, 50, 100],
      configTable: [
        {
          caption: this.$t('golf_config_invoice_form'),
          field: 'action',
          cellTemplate: 'FormNo',
          isShow: true,
          alignment: 'center',
          width: '35%',
        },
        {
          caption: this.$t('golf_config_invoice_serial'),
          field: 'SerialNo',
          isShow: true,
          alignment: 'center',
          width: '35%',
        },
        {
          caption: this.$t('golf_config_invoice_form_serial_active'),
          field: 'action',
          cellTemplate: 'actionTable',
          isShow: true,
          alignment: 'center',
          width: '20%',
        },
      ],
      // search: {
      //   FromDate: this.getUTC('start', 'day'),
      //   ToDate: this.getUTC('start', 'day'),
      //   Action: [],
      //   BDC: [],
      //   CashierCode: null
      // },
      listSale: null,
      windowHeight: 0,
      heightTable: 400,
      isLoading: false,
    }
  },
  created() {
    //this.getConfig_RCOM01()
    //this.getListCashier_RCS01()
    this.getListFormNoSerialNo()
  },
  computed: {
    dataGrid() {
      return this.$refs[dataGridRef].instance
    },
  },
  watch: {
    windowHeight(value) {
      if (value > 600) {
        this.heightTable = value - 200
      }
    },
    DetailInvoiceFormNoSerialNo(value) {
      if (this.CheckLishHasDefaultInovice === true && value.DefaultInvoiceSymbol === false) {
        this.IsDefaultInovice = true
      }
    },
    

  },
  methods: {
    // async getConfig_RCOM01() {
    //   this.listAction = []
    //   const responseBooking_Config = await booking.getConfigInfo({ KeyGroup: 'BOOKING_CONFIG' })
    //   const listActionConfig = JSON.parse(responseBooking_Config.Data.find(x => x.KeyCode === 'BOOKING_REVERT_BDC_ACTION').KeyValue)
    //   const responseTransaction_Config = await booking.getConfigInfo({ KeyGroup: 'TRANSACTION_CONFIG' })
    //   if (responseTransaction_Config.Status === '200') {
    //     responseTransaction_Config.Data.forEach(x => {
    //       if (listActionConfig.find(y => y === x.KeyCode)) {
    //         this.listAction.push(x)
    //       }
    //     });
    //   }
    // },
    getSelectedData() {
      this.selectedRowsData = this.dataGrid.getSelectedRowsData()
    },
    async createFormNoSerialNo() {
      this.disableButton = true
      const body = {
        InvoiceConfig: this.InvoiceFormNoSerialNo,
      }
      const response = await invoice.api_IICF01(body)
      this.showResToast(response)
      if (response.Status === '200') {
        this.hideModal()
        this.disableButton = false
        this.getListFormNoSerialNo()
      } else {
        this.disableButton = false
      }
    },
    async updateFormNoSerialNo() {
      this.disableButton = true
      console.log(this.InvoiceFormNoSerialNo)
      const body = {
        InvoiceConfig: this.InvoiceFormNoSerialNo,
      }
      const response = await invoice.api_UICF01(body)
      this.showResToast(response)
      if (response.Status === '200') {
        this.hideModal()
        this.disableButton = false
        this.getListFormNoSerialNo()
      } else {
        this.disableButton = false
      }
    },
    async updateFormNoSerialNoStatus(item) {
      this.isLoading = true
      console.log(item)
      const body = {
        InvoiceConfig: item,
      }
      const response = await invoice.api_UICF01(body)
      this.showResToast(response)
      if (response.Status === '200') {
        //this.hideModal()
        this.getListFormNoSerialNo()
        //this.isLoading = false
      } else {
        this.isLoading = false
      }
    },
    configInvoice_onRowClick(item) {
      this.DetailInvoiceFormNoSerialNo = item.data
    },
    async getListFormNoSerialNo() {
      this.isLoading = true
      const response = await invoice.api_RICF01()
      this.isLoading = false
      if (response.Status === '200') {
        this.ListFormNoSerialNo = response.Data.InvoiceConfig
        const checkDefaultInvoice = this.ListFormNoSerialNo.filter(x => x.DefaultInvoiceSymbol === true)
        if (checkDefaultInvoice != null && checkDefaultInvoice.length > 0) {
          this.CheckLishHasDefaultInovice = true
        } else {
          this.CheckLishHasDefaultInovice = false
        }
      }
    },
    async deleteFormNoSerialNo() {
      this.disableButton = true
      this.getSelectedData()
      if(this.selectedRowsData.length == 1) {
        const body = {
          InvoiceConfigId:[this.selectedRowsData[0].Id]
      }
      const response = await invoice.api_DICF01(body)
      this.showResToast(response)
      if(response.Status === '200') {
        this.getListFormNoSerialNo()
        this.disableButton = false
      } else {
        this.isLoading = false
        this.disableButton = false
      }
    }
      
    },
    async confimDeleteFormNoSerialNo(){
      this.getSelectedData()
      if(this.selectedRowsData.length == 1) {
        this.$refs.deleteDialogComponent.open({
          dataConfirm: {
            title: this.$t('golf_config_invoice_form_serial_invoice_delete'),
            content: this.$t('golf_golf_config_invoice_confirm_delete')
          }
        })
      } else {
        this.showToast('error', this.$t('golf_invoice_please_select_one_invoice'))
        this.isLoading = false
      }
      
    },
    // async getListCashier_RCS01() {
    //   await booking.api_RCS01().then(response => {
    //     if (response.Status === '200') {
    //       this.listSale = response?.Data?.Cashier.map(x => ({
    //         id: x.Id,
    //         label: x.CashierName,
    //       }))
    //     }
    //   })
    // },
    hideModal() {
      this.$bvModal.hide('modal-config-invoice-form-serial-detail')
    },
    openAddConfigInvoiceFormNoSerialNo() {
      this.deleteFormNoSerialNo = 'ADD'
      console.log(this.type)
      
      this.InvoiceFormNoSerialNo.FormNo = null
      this.InvoiceFormNoSerialNo.SerialNo = null
      this.InvoiceFormNoSerialNo.Active = true
      this.InvoiceFormNoSerialNo.DefaultInvoiceSymbol = false
      if (this.CheckLishHasDefaultInovice) {
        this.IsDefaultInovice = true
      } else {
        this.IsDefaultInovice = false
      }
      
      this.$root.$emit('bv::show::modal', 'modal-config-invoice-form-serial-detail')
      // this.dataPropsModalConfigInvoiceFormNoSerialDetail.type = 'ADD'
      // // this.dataPropsModalConfigInvoiceFormNoSerialDetail.data = {
      // //   FormNo: null,
      // //   SerialNo: null,
      // //   Active: false,
      // // }
      // console.log('a')
      // console.log(this.dataPropsModalConfigInvoiceFormNoSerialDetail)
      // this.$root.$emit('bv::show::modal', 'modal-locker-area')
      // console.log('b')
    },
    UpdateConfigInvoiceFormNoSerialNo() {
      this.getSelectedData()
      console.log(this.selectedRowsData.length)
      if(this.selectedRowsData.length != 1) {
        this.showToast('error', this.$t('golf_config_invoice_form_serial_please_select_one'))
       }
       else{
        if(this.DetailInvoiceFormNoSerialNo !=null){
          this.type = 'DETAIL'
          this.InvoiceFormNoSerialNo.FormNo = this.DetailInvoiceFormNoSerialNo.FormNo
          this.InvoiceFormNoSerialNo.SerialNo = this.DetailInvoiceFormNoSerialNo.SerialNo
          this.InvoiceFormNoSerialNo.Active = this.DetailInvoiceFormNoSerialNo.Active
          this.InvoiceFormNoSerialNo.Id = this.DetailInvoiceFormNoSerialNo.Id
          this.InvoiceFormNoSerialNo.DefaultInvoiceSymbol = this.DetailInvoiceFormNoSerialNo.DefaultInvoiceSymbol
          console.log(this.CheckLishHasDefaultInovice)
          console.log(this.DetailInvoiceFormNoSerialNo.DefaultInvoiceSymbol)
          if (this.CheckLishHasDefaultInovice === true && this.DetailInvoiceFormNoSerialNo.DefaultInvoiceSymbol === false) {
            this.IsDefaultInovice = true
          } else {
            this.IsDefaultInovice = false
          }
         }
        //this.dataPropsModalConfigInvoiceFormNoSerialDetail.type = 'DETAIL'
        //this.dataPropsModalConfigInvoiceFormNoSerialDetail.data = data
        this.$root.$emit('bv::show::modal', 'modal-config-invoice-form-serial-detail')
       }
    },
    // exportExcel() {
    //   const workbook = new Workbook()
    //   const worksheet = workbook.addWorksheet('Booking')
    //   const timeZone = localStorage.getItem('timeZone')
    //   const listActionTemp = this.listAction
    //   exportDataGrid({
    //     component: this.dataGrid,
    //     worksheet,
    //     autoFilterEnabled: true,
    //     PrintSelectedRowsOnly: true,
    //     topLeftCell: { row: 4, column: 1 },
    //     customizeCell: function (options) {
    //       const { gridCell, excelCell } = options;
    //       if (gridCell.rowType === 'data') {
    //         if (gridCell.column.dataField == 'CreateDate') {
    //           excelCell.value = convertUTC(gridCell.value, 'DD/MM/YYYY')
    //         }
    //         if (gridCell.column.dataField == 'UpdateDate') {
    //           excelCell.value = convertUTC(gridCell.value, 'DD/MM/YYYY')
    //         }
    //         if (gridCell.column.dataField == 'Action') {
    //           excelCell.value = listActionTemp.find(x => x.KeyCode == gridCell.value) ? listActionTemp.find(x => x.KeyCode == gridCell.value).KeyValue : ''
    //         }
    //       }
    //     }
    //   }).then(cellRange => {
    //     // header
    //     const headerRow = worksheet.getRow(2)
    //     headerRow.height = 30
    //     worksheet.mergeCells(2, 1, 2, 8)

    //     headerRow.getCell(1).value = `DANH SÁCH BDC (từ ${this.convertUTC(this.search.FromDate, 'DD/MM/YYYY')} đến ${this.convertUTC(this.search.ToDate, 'DD/MM/YYYY')})`
    //     headerRow.getCell(1).font = { name: 'Segoe UI Light', size: 22 }
    //     headerRow.getCell(1).alignment = { horizontal: 'center' }

    //     // footer
    //     const footerRowIndex = cellRange.to.row + 2
    //     const footerRow = worksheet.getRow(footerRowIndex)
    //     worksheet.mergeCells(footerRowIndex, 1, footerRowIndex, 8)
    //     footerRow.getCell(1).font = { color: { argb: 'BFBFBF' }, italic: true }
    //     footerRow.getCell(1).alignment = { horizontal: 'right' }
    //   }).then(() => {
    //     workbook.xlsx.writeBuffer().then(buffer => {
    //       saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `BDCRevert${this.convertUTC(this.search.FromDate, 'DD/MM/YYYY')}-${this.convertUTC(this.search.ToDate, 'DD/MM/YYYY')}.xlsx`)
    //     })
    //   })
    // }
  },
}